import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/Layout'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import { Context } from '../Context'
import { isBrowser } from '../components/Auth'
import { getUsers } from '../services/api/users'
import { getRoles, putUserRoles, deleteUserRoles } from '../services/api/roles'

export default function EditUserRolesPage (props) {
  const { isAuth } = useContext(Context)

  if (isBrowser() && !isAuth) {
    if (isBrowser()) {
      window.location.href = '/'
    }

    return (
      <Layout withoutHeader className='page-users'>
        Cargando...
      </Layout>
    )
  }

  const [user, setUser] = useState({
    name: '',
    name2: '',
    lastname: '',
    lastname2: ''
  })
  const [roles, setRoles] = useState([])
  const params = queryString.parse(props.location.search)

  // carga el usuario
  useEffect(() => {
    getUsers({ id: params.user_id })
      .then(res => {
        if (res.data.length > 0) {
          setUser(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
      })

    getRoles()
      .then(res => {
        setRoles(res.data)
      })
      .catch(err => console.dir(err))
  }, [])

  const onSubmit = values => {
    if (values.roles.length === 0) {
      deleteUserRoles(params.user_id)
        .then(res => {
          toast.success('Se han actualizado los roles de usuario')
          navigate('/users/')
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
    } else {
      putUserRoles(params.user_id, values.roles)
        .then(() => {
          toast.success('Se han actualizado los roles de usuario')
          navigate('/users/')
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
    }
  }

  return (
    <Layout>
      <div
        style={{
          maxWidth: '500px',
          margin: '3rem auto',
          color: 'white'
        }}
      >
        <h2 style={{ color: 'white' }}>
          Roles de {user.name} {user.name2} {user.lastname}
        </h2>
        <Formik
          onSubmit={onSubmit}
          initialValues={{ roles: user.roles }}
          enableReinitialize
          render={({ values, setFieldValue }) => (
            <Form>
              <div>
                {roles.map(rol => {
                  return (
                    <p key={rol.id}>
                      <label>
                        <input
                          type='checkbox'
                          style={{ marginRight: '10px' }}
                          defaultChecked={
                            values.roles && values.roles.find(r => r === rol.id)
                          }
                          onClick={e => {
                            if (e.target.checked) {
                              if (!values.roles) values.roles = []
                              values.roles.push(rol.id)
                              setFieldValue('roles', values.roles)
                            } else {
                              setFieldValue(
                                'roles',
                                values.roles.filter(item => item !== rol.id)
                              )
                            }
                          }}
                        />
                        {rol.name}
                      </label>
                    </p>
                  )
                })}
              </div>

              <div style={{ marginTop: '2rem' }}>
                <button
                  type='submit'
                  style={{
                    fontSize: '1rem',
                    backgroundColor: 'white',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '0.25rem 1rem',
                    color: 'var(--color2)',
                    fontWeight: 'bold'
                  }}
                >
                  GUARDAR
                </button>
              </div>
            </Form>
          )}
        />
      </div>
    </Layout>
  )
}
